<template>
  <div>
    <h2 class="mb-4">Список промокодов</h2>

    <data-table
      url="discounts/promo-codes"
      :headers="headers"
      item-key="name"
      clickable-rows
      @click:row="showPromocode"
    >
      <template v-slot:[`item.name`]="{ item: promocode }">
        <router-link :to="{ name: 'promocodes-id', params: { id: promocode.name } }">
          {{ promocode.name }}
        </router-link>
      </template>

      <template v-slot:[`item.isActive`]="{ item: promocode }">{{ promocode.isActive | yesNo }}</template>
      <template v-slot:[`item.createdAt`]="{ item: promocode }">{{ promocode.createdAt | dateTime }}</template>
      <template v-slot:[`item.expiredAt`]="{ item: promocode }">{{ promocode.expiredAt | dateTime }}</template>
    </data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Имя", value: "name" },
        { text: "Описание", value: "description" },
        { text: "Активен", value: "isActive" },
        { text: "Дата создания", value: "createdAt" },
        { text: "Дата истечения", value: "expiredAt" }
      ]
    };
  },
  methods: {
    showPromocode(promocode) {
      this.$router.push({ name: "promocodes-id", params: { id: promocode.name } });
    }
  }
};
</script>
